import React, { useCallback, useState } from 'react';
import "primereact/resources/themes/lara-light-cyan/theme.css";
import 'primeicons/primeicons.css';

import { 
  Home,
  KindOfCinema,
  MovieDetails,
  MoviesResults,
  MySpecification,
  SelectCountry,
  SelectStreamingServices,
  YourMood,
} from './screens';
import { EnumPath, MediaEntry } from './utils';
import { countryType } from './screens/SelectCountry/interfaces';
import { serviceStreamintType } from './screens/SelectStreamingServices/interfaces';
import { kindType } from './screens/KindOfCinema/interfaces';
import { moodType } from './screens/YourMood/interfaces';

const __MOCKS__ = {
  "result": [
      {
          "genres": "drm, crm",
          "imdb_id": "tt0264464",
          "offers": [
              {
                  "icon": "https://images.justwatch.com/icon/52449861/s100/amazonprimevideo.png",
                  "monetizationType": "FLATRATE",
                  "platform": "Amazon Prime Video"
              },
              {
                  "icon": "https://images.justwatch.com/icon/190848813/s100/itunes.png",
                  "monetizationType": "RENT",
                  "platform": "Apple TV"
              },
              {
                  "icon": "https://images.justwatch.com/icon/169478387/s100/play.png",
                  "monetizationType": "RENT",
                  "platform": "Google Play Movies"
              },
              {
                  "icon": "https://images.justwatch.com/icon/812139/s100/orange.png",
                  "monetizationType": "RENT",
                  "platform": "Orange VOD"
              },
              {
                  "icon": "https://images.justwatch.com/icon/820542/s100/microsoft.png",
                  "monetizationType": "RENT",
                  "platform": "Microsoft Store"
              },
              {
                  "icon": "https://images.justwatch.com/icon/59562423/s100/youtube.png",
                  "monetizationType": "RENT",
                  "platform": "YouTube"
              },
              {
                  "icon": "https://images.justwatch.com/icon/107895447/s100/canalplayvod.png",
                  "monetizationType": "RENT",
                  "platform": "Canal VOD"
              },
              {
                  "icon": "https://images.justwatch.com/icon/128599720/s100/wuaki.png",
                  "monetizationType": "RENT",
                  "platform": "Rakuten TV"
              },
              {
                  "icon": "https://images.justwatch.com/icon/307421398/s100/lacinetek.png",
                  "monetizationType": "RENT",
                  "platform": "LaCinetek"
              },
              {
                  "icon": "https://images.justwatch.com/icon/244252120/s100/universcine.png",
                  "monetizationType": "RENT",
                  "platform": "Universcine"
              },
              {
                  "icon": "https://images.justwatch.com/icon/430993/s100/amazon.png",
                  "monetizationType": "RENT",
                  "platform": "Amazon Video"
              }
          ],
          "poster": "https://images.justwatch.com/poster/312985367/s718/arrete-moi-si-tu-peux.jpg",
          "release_date": "2002-12-16",
          "runtime_minutes": 141,
          "short_description": "Dans les années soixante, le jeune Frank Abagnale Jr. est passé maître dans l’art de l’escroquerie, allant jusqu’à détourner 2,5 millions de dollars et à figurer sur les listes du FBI comme l’un des dix individus les plus recherchés des États‐Unis. Véritable caméléon, Frank revêt des identités aussi diverses que celles de pilote de ligne, de médecin, de professeur d’université ou encore d’assistant du procureur. Carl Hanratty, agent du FBI à l’apparence stricte, fait de la traque de Frank Abagnale Jr. sa mission prioritaire, mais ce dernier reste pendant longtemps insaisissable…",
          "title": "Arrête-moi si tu peux",
          "imdb_rate": {
              "rate": "8.1",
              "totalFeedBack": "1.1M"
          },
          "originalTitle": "Catch Me If You Can"
      },
      {
          "genres": "cmy, drm, scf, rma, fnt",
          "imdb_id": "tt1841321",
          "offers": [
              {
                  "icon": "https://images.justwatch.com/icon/207360008/s100/netflix.png",
                  "monetizationType": "FLATRATE",
                  "platform": "Netflix"
              },
              {
                  "icon": "https://images.justwatch.com/icon/301462744/s100/netflixbasicwithads.png",
                  "monetizationType": "FLATRATE",
                  "platform": "Netflix basic with Ads"
              }
          ],
          "poster": "https://images.justwatch.com/poster/17371141/s718/secret-garden.jpg",
          "release_date": "2010-11-13",
          "runtime_minutes": 61,
          "short_description": "Kim Ju Won est un bel homme, arrogant, puéril, propriétaire d'un grand centre commercial et très attaché à son rang social.\n\nGil Ra Im est une pauvre, talentueuse et belle cascadeuse.\n\nLes chemins de Ra Im et Ju Won se croisent mais les différences de milieux, de manière de vivre et de voir la vie entraînent une dispute.\n\nUn jour, Kim Ju Won et Gil Ra Im s'enfoncent dans une forêt à Jeju et trouvent une étrange maison où réside une dame qui leur offre une bouteille d'alcool. Le lendemain, après l'avoir bue, ils sentent un étrange changement physique… ils ont échangé leurs corps. Ne sachant pas quoi faire, ils décident de mener chacun la vie de l'autre.\n\nAutour d'eux gravitent des personnages qui ne doivent pas apprendre cette histoire. Oska le cousin de Ju Won, un coureur de jupons autrefois chanteur populaire, aujourd'hui une star oubliée. Yun Seul, fille de bonne famille ; en couple avec Oska il y a plusieurs années, elle tente aujourd'hui d'épouser Ju Won.",
          "title": "Secret Garden",
          "imdb_rate": {
              "rate": "8.1",
              "totalFeedBack": "7.7K"
          },
          "originalTitle": "The Secret Garden"
      },
      {
          "genres": "drm, eur",
          "imdb_id": "tt0454921",
          "offers": [
              {
                  "icon": "https://images.justwatch.com/icon/207360008/s100/netflix.png",
                  "monetizationType": "FLATRATE",
                  "platform": "Netflix"
              },
              {
                  "icon": "https://images.justwatch.com/icon/128599720/s100/wuaki.png",
                  "monetizationType": "ADS",
                  "platform": "Rakuten TV"
              },
              {
                  "icon": "https://images.justwatch.com/icon/301462744/s100/netflixbasicwithads.png",
                  "monetizationType": "FLATRATE",
                  "platform": "Netflix basic with Ads"
              },
              {
                  "icon": "https://images.justwatch.com/icon/812139/s100/orange.png",
                  "monetizationType": "RENT",
                  "platform": "Orange VOD"
              },
              {
                  "icon": "https://images.justwatch.com/icon/259364847/s100/filmotv.png",
                  "monetizationType": "RENT",
                  "platform": "FILMO"
              },
              {
                  "icon": "https://images.justwatch.com/icon/107895447/s100/canalplayvod.png",
                  "monetizationType": "RENT",
                  "platform": "Canal VOD"
              },
              {
                  "icon": "https://images.justwatch.com/icon/430993/s100/amazon.png",
                  "monetizationType": "RENT",
                  "platform": "Amazon Video"
              },
              {
                  "icon": "https://images.justwatch.com/icon/190848813/s100/itunes.png",
                  "monetizationType": "RENT",
                  "platform": "Apple TV"
              },
              {
                  "icon": "https://images.justwatch.com/icon/169478387/s100/play.png",
                  "monetizationType": "RENT",
                  "platform": "Google Play Movies"
              },
              {
                  "icon": "https://images.justwatch.com/icon/820542/s100/microsoft.png",
                  "monetizationType": "RENT",
                  "platform": "Microsoft Store"
              },
              {
                  "icon": "https://images.justwatch.com/icon/59562423/s100/youtube.png",
                  "monetizationType": "RENT",
                  "platform": "YouTube"
              },
              {
                  "icon": "https://images.justwatch.com/icon/810793/s100/bbox.png",
                  "monetizationType": "RENT",
                  "platform": "Bbox VOD"
              }
          ],
          "poster": "https://images.justwatch.com/poster/9030429/s718/a-la-recherche-du-bonheur.jpg",
          "release_date": "2006-12-14",
          "runtime_minutes": 117,
          "short_description": "Représentant de commerce, Chris Gardner a du mal à gagner sa vie. Il jongle pour s'en sortir, mais sa compagne supporte de moins en moins leur précarité. Elle finit par quitter Chris et leur petit garçon de cinq ans, Christopher. Désormais seul responsable de son fils, Chris se démène pour décrocher un job, sans succès. Lorsqu'il obtient finalement un stage dans une prestigieuse firme de courtage, il se donne à fond.",
          "title": "À la recherche du bonheur",
          "imdb_rate": {
              "rate": "8.0",
              "totalFeedBack": "564K"
          },
          "originalTitle": "The Pursuit of Happyness"
      }
  ],
  "excludedMovies": [
      {
          "title": "Wonder",
          "year": 2017
      },
      {
          "title": "The Intern",
          "year": 2015
      },
      {
          "title": "Finding Dory",
          "year": 2016
      },
      {
          "title": "The Bucket List",
          "year": 2007
      },
      {
          "title": "Catch Me If You Can",
          "year": 2002
      },
      {
          "title": "Mrs. Doubtfire",
          "year": 1993
      },
      {
          "title": "The Secret Garden",
          "year": 1993
      },
      {
          "title": "Saving Mr. Banks",
          "year": 2013
      },
      {
          "title": "Ratatouille",
          "year": 2007
      },
      {
          "title": "Inside Out",
          "year": 2015
      },
      {
          "title": "The Pursuit of Happyness",
          "year": 2006
      },
      {
          "title": "Big",
          "year": 1988
      },
      {
          "title": "Enchanted",
          "year": 2007
      },
      {
          "title": "Waking Ned Devine",
          "year": 1998
      },
      {
          "title": "The Hundred-Year-Old Man Who Climbed Out of the Window and Disappeared",
          "year": 2013
      },
      {
          "title": "As Good as It Gets",
          "year": 1997
      },
      {
          "title": "Wonder Boys",
          "year": 2000
      },
      {
          "title": "The Best Little Whorehouse in Texas",
          "year": 1982
      },
      {
          "title": "While You Were Sleeping",
          "year": 1995
      },
      {
          "title": "The Princess Bride",
          "year": 1987
      }
  ]
};

const baseUrl = 'https://safebot.fr';
// const baseUrl = 'http://localhost:8242';

function App() {

  const [activeScren, setActiveScreen] = useState<EnumPath>(EnumPath.Home);
  const [mediaEntries, setMediaEntries] = useState<MediaEntry[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [selectedCountry, setSelectedCountry] = useState<countryType | null>(null);
  const [selecteServices, setSelectedService] = useState<serviceStreamintType[]>([]);
  const [selectedKindOfCinema, setSelectedKindOfCinema] = useState<kindType[]>([]);
  const [selectedMoods, setSelectedMoods] = useState<moodType[]>([]);
  const [specifications, setSpecifications] = useState<string>('');
  const [excludedMovies, setExcludedMovies] = useState<any[]>([]);

  const userLanguage = navigator.language;
  const isFR = userLanguage.toLocaleLowerCase().includes('fr');
  const locale = isFR ? 'fr' : 'en';

  const onClear = useCallback(()=> {
    setSelectedCountry(null);
    setSelectedService([]);
    setSelectedKindOfCinema([]);
    setSelectedMoods([]);
    setSpecifications('');
    setExcludedMovies([]); 
    setMediaEntries([]);
  }, []);

  const onGetNewSearch = useCallback(async()=> {
    await onClear();
    setActiveScreen(EnumPath.SelectCountry);
  }, [onClear]);

  const findRecomandation = useCallback(async(specificationTxt?: string)=> {
    setIsLoading(true);

    const userPreferencies = {
      countryCode: selectedCountry?.code!,
      language: locale,
      streamingServices: selecteServices.map(el=> el.name),
      kindOfCinema: selectedKindOfCinema.map(el=> el.value).join(),
      moods: selectedMoods.map(el=> el.name).join(),
      specifications: specificationTxt,
      ...(excludedMovies.length > 0 ? {excludedMovies: excludedMovies} : {}),
    };

    const req = await fetch(`${baseUrl}/api-movies/get-movies`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(userPreferencies),
    });
    const response = await req.json();

    setMediaEntries(e=> e.concat(response.result));
    setExcludedMovies(e=> e.concat(response.excludedMovies));

    // setMediaEntries(e=> e.concat(__MOCKS__.result));
    // setExcludedMovies(e=> e.concat(__MOCKS__.excludedMovies));

    setIsLoading(false);
  }, [
    selectedCountry, locale, 
    selecteServices, selectedKindOfCinema, 
    selectedMoods, excludedMovies,
  ]);

  const renderApp = useCallback(()=> {
    switch (activeScren) {
      case EnumPath.Home:
        return (
          <Home 
            onNextPage={()=> setActiveScreen(EnumPath.SelectCountry)}
          />
        );
      case EnumPath.SelectCountry:
        return (
          <SelectCountry 
            selectedCountryDefault={selectedCountry}
            onNextPage={(country: countryType)=> {
              setSelectedCountry(country);
              setActiveScreen(EnumPath.SelectStreamingServices);
            }}
            onPrevPage={()=> setActiveScreen(EnumPath.Home)}
          />
        );
      case EnumPath.SelectStreamingServices:
        return (
          <SelectStreamingServices 
            onNextPage={(services)=> {
              setSelectedService(services);
              setActiveScreen(EnumPath.KindOfCinema);
            }}
            onPrevPage={()=> setActiveScreen(EnumPath.SelectCountry)}
            selectedServicesDefault={selecteServices}
          />
        )
      case EnumPath.KindOfCinema:
        return (
          <KindOfCinema 
            selectedKindOfCinemaDefault={selectedKindOfCinema}
            onNextPage={(kinds)=> {
              setSelectedKindOfCinema(kinds);
              setActiveScreen(EnumPath.YourMood);
            }}
            onPrevPage={()=> setActiveScreen(EnumPath.SelectStreamingServices)}
          />
        )
      case EnumPath.YourMood:
        return (
          <YourMood 
            selectedMoodsDefault={selectedMoods}
            onNextPage={(moods)=> {
              if(moods){
                setSelectedMoods(moods);
              }
              setActiveScreen(EnumPath.MySpecification);
            }}
            onPrevPage={()=> setActiveScreen(EnumPath.KindOfCinema)}
          />
        )
      case EnumPath.MySpecification:
        return (
          <MySpecification 
            specificationsDefault={specifications}
            onNextPage={(specificationTxt)=> {
              if(specificationTxt){
                setSpecifications(specificationTxt);
              }
              setActiveScreen(EnumPath.MoviesResults);
              findRecomandation(specificationTxt);
            }}
            onPrevPage={()=> setActiveScreen(EnumPath.YourMood)}
          />
        )
      case EnumPath.MoviesResults:
        return (
          <MoviesResults 
            // onNextPage={()=> setActiveScreen(EnumPath.MovieDetails)}
            onPrevPage={()=> setActiveScreen(EnumPath.YourMood)}
            onGetMoreSuggestion={()=> findRecomandation(specifications)}
            onGetNewSearch={onGetNewSearch}
            mediaEntries={mediaEntries}
            isLoading={isLoading}
          />
        )
      case EnumPath.MovieDetails:
        return (
          <MovieDetails 
            
          />
        )
      
    
      default:
        return (
          <Home 
            onNextPage={()=> setActiveScreen(EnumPath.SelectCountry)}
          />
        );
    }
  }, [
      activeScren, selectedCountry, 
      selecteServices, selectedKindOfCinema, 
      selectedMoods, specifications, mediaEntries, 
      isLoading, findRecomandation
    ]);

  return renderApp();
}

export default App;
