import { useTranslation } from "../../utils";

export const useController = ()=> {

    const translate = useTranslation();

    const getYear = (date: Date)=> {
        return String(date.getFullYear());
    }
    
    const formatTextMaxChars = (text: string, maxChars: number)=> {
        if (text.length > maxChars) {
          return text.slice(0, maxChars) + "...";
        }
        return text;
    }
    
    const getGenres = (codesString: string)=> {
        const genreMap:any = {
            "act": translate("act"),
            "ani": translate("ani"),
            "cmy": translate("cmy"),
            "crm": translate("crm"),
            "doc": translate("doc"),
            "drm": translate("drm"),
            "fam": translate("fam"),
            "fnt": translate("fnt"),
            "hrr": translate("hrr"),
            "mus": translate("mus"),
            "trl": translate("trl"),
            "rma": translate("rma"),
            "scf": translate("scf"),
            "spt": translate("spt"),
            "war": translate("war"),
            "wes": translate("wes"),
            "rly": translate("rly"),
        };
    
        const codesArray = codesString.split(", ").map(code => code.trim());
        let genresArray:string[] = [];
        codesArray.map(code => {
            if(genreMap[code]){
                genresArray.push(genreMap[code])
            }
        });

        return genresArray;
    }
    

    return {
        getYear,
        formatTextMaxChars,
        getGenres,
    };
}
