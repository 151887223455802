import React from "react";
import { SelectStreamingServicesComponent } from "./SelectStreamingServices.component";
import { ISelectStreamingServices } from "./interfaces";

export const SelectStreamingServices:React.FC<ISelectStreamingServices> = ({
    selectedServicesDefault,
    onNextPage,
    onPrevPage,
})=> {
    return (
        <SelectStreamingServicesComponent 
            selectedServicesDefault={selectedServicesDefault}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
        />
    )
}