import React from "react";
import { SelectCountryComponent } from "./SelectCountry.component";
import { ISelectCountry } from "./interfaces";

export const SelectCountry:React.FC<ISelectCountry> = ({
    onNextPage,
    onPrevPage,
    selectedCountryDefault,
})=> {
    return (
        <SelectCountryComponent 
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            selectedCountryDefault={selectedCountryDefault}
        />
    )
}