import React from "react";
import './styles.css';
import { IHome } from "./interfaces";
import { GradianButton, ScreenWrapper } from "../../components";

import Logo from '../../assets/logo/logo.svg';
import { useTranslation } from "../../utils";

export const HomeComponent:React.FC<IHome> = ({
    onNextPage
})=> {

    const translate = useTranslation();

    return (
        <ScreenWrapper>
            <div className="pc_home_container">
                <img 
                    src={Logo} 
                    alt="logo" 
                    className="pc_home_logo"
                />

                <h1 className="pc_home_title">
                    {translate('grab_the_bucket')}
                </h1>

                <p className="pc_home_txt">
                    {translate('find_movies_and_shows')}
                </p>

                <GradianButton 
                    label={translate('get_in_the_zone')}
                    classname="pc_home_button"
                    onPress={onNextPage}
                />

                <p className="pc_home_txt_span">
                    {translate('ai_powered_emoji')}
                </p>
                <p className="pc_home_txt_span">
                    {translate('ai_powered')}
                </p>
            </div>
        </ScreenWrapper>
    )
}