import React from "react";
import { YourMoodComponent } from "./YourMood.component";
import { IYourMood } from "./interfaces";

export const YourMood:React.FC<IYourMood> = ({
    selectedMoodsDefault,
    onNextPage,
    onPrevPage,
})=> {
    return (
        <YourMoodComponent 
            selectedMoodsDefault={selectedMoodsDefault}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
        />
    )
}