import React, { useCallback, useMemo } from "react";
import './styles.css';
import { ISelectCountry } from "./interfaces";

import { Dropdown } from 'primereact/dropdown';
import { ChevronDownIcon } from 'primereact/icons/chevrondown';
import { ChevronRightIcon } from 'primereact/icons/chevronright';
import { useTranslation } from "../../utils";

import BrasilFlag from '../../assets/flags/bzl.png';
import FranceFlag from '../../assets/flags/fra.png';
import GermanyFlag from '../../assets/flags/ger.png';
import SpainFlag from '../../assets/flags/esp.png';
import UsaFlag from '../../assets/flags/usa.png';
import JaponFlag from '../../assets/flags/jpn.png';
import IndiaFlag from '../../assets/flags/ind.png';
import AustraliaFlag from '../../assets/flags/aus.png';

export const SelectCountry:React.FC<ISelectCountry> = ({
    selectedCountry,
    setSelectedCountry,
})=> {

    const translate = useTranslation();
    const countries = useMemo(()=> (
        [
            { name: 'Australia', code: 'AU', flagIcon: AustraliaFlag },
            { name: 'Brazil', code: 'BR', flagIcon: BrasilFlag },
            { name: 'France', code: 'FR', flagIcon: FranceFlag },
            { name: 'Germany', code: 'DE', flagIcon: GermanyFlag },
            { name: 'India', code: 'IN', flagIcon: IndiaFlag },
            { name: 'Japan', code: 'JP', flagIcon: JaponFlag },
            { name: 'Spain', code: 'ES', flagIcon: SpainFlag },
            { name: 'United States', code: 'US', flagIcon: UsaFlag },
        ]
    ), []);

    const selectedCountryTemplate = useCallback((option: any, props: any) => {
        if (option) {
            return (
                <div className="pc_selectCountrySectionComponent">
                    <img 
                        alt={option.name} 
                        src={option.flagIcon} 
                        style={{ width: 30, marginRight: 10 }} 
                    />
                    <div>{option.name}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    }, []);

    const countryOptionTemplate = useCallback((option: any) => {
        return (
            <div className="pc_selectCountrySectionComponent">
                <img 
                    alt={option.name} 
                    src={option.flagIcon} 
                    style={{ width: 30, marginRight: 10 }} 
                />
                <div>{option.name}</div>
            </div>
        );
    }, []);

    const panelFooterTemplate = useCallback(() => {
        return (
            <div className='pc_selectedCountry_span_txt'>
                {selectedCountry ? (
                    <span >
                        <b>{selectedCountry.name}</b> {translate('selected')}
                    </span>
                ) : (
                    translate('no_country_selected')
                )}
            </div>
        );
    }, [selectedCountry, translate]);

    return (
        <Dropdown 
            optionLabel="name" 
            placeholder={translate('select_country')}
            value={selectedCountry} 
            onChange={(e) => setSelectedCountry(e.value)} 
            options={countries}  
            valueTemplate={selectedCountryTemplate} 
            itemTemplate={countryOptionTemplate} 
            panelClassName="pc_select_pannel"
            className="pc_select_container" panelFooterTemplate={panelFooterTemplate} 
            dropdownIcon={(opts: any) => {
                return opts.iconProps['data-pr-overlay-visible'] 
                        ? <ChevronRightIcon {...opts.iconProps} /> 
                        : <ChevronDownIcon {...opts.iconProps} />;
            }}
        />
    )
}