import React, { useCallback } from "react";
import './styles.css';
import { IScreenWrapper } from "./interfaces";

import leftArrow from '../../assets/icons/left-arrow.png';
import { GradianButton } from "../GradianButton";

export const ScreenWrapper:React.FC<IScreenWrapper> = ({
    isBlur,
    headTitle,
    headSubTitle,
    optional,
    onGoBackPress,
    bottomButtonLabel,
    bottomButtonPress,
    children,
})=> {

    const renderHeader = useCallback(()=> {
        if(!(onGoBackPress || headTitle || headSubTitle)) return;
        
        return (
            <div className="pc_screen_wrapper_header_container">
                {
                    onGoBackPress && (
                        <img 
                            src={leftArrow} 
                            alt="left arrow"
                            className="pc_arrow_left"
                            onClick={onGoBackPress}
                        />
                    )
                }

                <div className="pc_screen_wrapper_header_subcontainer">
                    {
                        headTitle && (
                            <p className="pc_screen_wraper_title_header">
                                {headTitle}
                            </p>
                        )
                    }

                    {
                        headSubTitle && (
                            <p className="pc_screen_wraper_subTitle_header">
                                {headSubTitle}
                            </p>
                        )
                    }

                    {
                        optional && (
                            <p className="pc_screen_wraper_optionalTxt_header">
                                ({optional})
                            </p>
                        )
                    }
                </div>
            </div>
        )
    }, [headTitle, headSubTitle, optional, onGoBackPress]);

    const renderBottomButton = useCallback(()=> {
        if(!(bottomButtonLabel && bottomButtonPress)) return;
        
        return (
            <div className="pc_screen_wrapper_footer">
                <GradianButton 
                    label={bottomButtonLabel}
                    onPress={bottomButtonPress}
                />
            </div>
        )
    }, [bottomButtonLabel, bottomButtonPress]);

    return (
        <div className={`pc_screen_wrapper_container ${isBlur && 'pc_blur_bg'}`}>
            {renderHeader()}

            <div className="pc_screen_wrapper_children_container">
                {children}
            </div>

            {renderBottomButton()}
        </div>
    )
}