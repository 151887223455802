import React, { useCallback, useMemo, useState } from "react";
import './styles.css';
import { IKindOfCinema, kindType } from "./interfaces";
import { Button, DesktopSection, ScreenWrapper } from "../../components";
import { useTranslation } from "../../utils";

export const KindOfCinemaComponent:React.FC<IKindOfCinema> = ({
    selectedKindOfCinemaDefault,
    onNextPage,
    onPrevPage,
})=> {

    const translate = useTranslation();
    const [selectedKindOfCinema, setSelectedKindOfCinema] = useState<kindType>(selectedKindOfCinemaDefault[0]);

    const kindOfCinemaList = useMemo(()=> (
        [
            {name: translate('tv_show'), value: 'TV show',color: '#fff', backgroundColor: '#000'},
            {name: translate('movie'), value: 'Movies', color: '#fff', backgroundColor: '#000'},
            {name: translate('documentary'), value: 'Documentary', color: '#fff', backgroundColor: '#000'},
            {name: translate('reality_show'), value: 'Reality show', color: '#fff', backgroundColor: '#000'},
            {name: translate('no_preference'), value: 'TV show, Movies & Documentary', color: '#fff', backgroundColor: '#000'},
        ]
    ), [translate]);

    const handleSelectedKind = useCallback((el: kindType)=> {
        setSelectedKindOfCinema(el);
    }, []);

    const handleNextPage = useCallback(()=> {
        if(!selectedKindOfCinema) return;

        onNextPage([selectedKindOfCinema]);
    }, [onNextPage, selectedKindOfCinema]);

    const renderContent = useCallback(()=> (
        <div className="pc_kindOfCinema_container">
            <div className="pc_kindOfCinema_listItems">
                {
                    kindOfCinemaList.map((el, i)=> {
                        const exist = el.name === selectedKindOfCinema?.name;
                        return (
                            <Button 
                                key={i}
                                label={el.name}
                                onPress={()=> handleSelectedKind(el)}
                                color={exist ? el.color : ''}
                                backgroundColor={exist ? el.backgroundColor : ''}
                                noBorder={exist}
                            />
                        )
                    })
                }
            </div>
        </div>
    ), [handleSelectedKind, kindOfCinemaList, selectedKindOfCinema]);

    return (
        <ScreenWrapper
            isBlur
            headTitle="3 — 5"
            headSubTitle={translate('what_kind_of_cinema_are_you_searching_for')}
            bottomButtonLabel={translate('next')}
            onGoBackPress={onPrevPage}
            bottomButtonPress={handleNextPage}
        >
            <DesktopSection>
                {renderContent()}
            </DesktopSection>
        </ScreenWrapper>
    )
}