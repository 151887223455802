import React, { useCallback, useMemo, useState } from "react";
import './styles.css';
import { IMySpecification } from "./interfaces";
import { DesktopSection, ScreenWrapper } from "../../components";
import { useTranslation } from "../../utils";

export const MySpecificationComponent:React.FC<IMySpecification> = ({
    specificationsDefault,
    onNextPage,
    onPrevPage
})=> {

    const translate = useTranslation();

    const [userTxt, setUserTxt] = useState<string>(specificationsDefault);

// attention ne pas indenter car back quote senssible à la casse
    const placeholder = useMemo(()=> (
`${translate('example_1')}

${translate('example_2')}

${translate('example_3')}
`
    ), [translate]);
    
    const handleNextPage = useCallback(()=> {
        onNextPage(userTxt);
    }, [userTxt, onNextPage]);

    const renderContent = useCallback(()=> (
        <div className="pc_mySpecification_container">
            <textarea 
                value={userTxt}
                onChange={e=> setUserTxt(e.target.value)}
                rows={12}
                className="pc_textArea"
                placeholder={placeholder}
            />
        </div>
    ), [placeholder, userTxt]);

    return (
        <ScreenWrapper
            isBlur
            headTitle="5 — 5"
            headSubTitle={translate('extra_specifications')}
            optional={translate('optional')}
            bottomButtonLabel={translate('done')}
            onGoBackPress={onPrevPage}
            bottomButtonPress={handleNextPage}
        >
            <DesktopSection>
                {renderContent()}
            </DesktopSection>
        </ScreenWrapper>
    )
}