import React from "react";
import { HomeComponent } from "./Home.component";

interface Props {
    onNextPage: ()=> void
}

export const Home:React.FC<Props> = ({
    onNextPage
})=> {
    return (
        <HomeComponent 
            onNextPage={onNextPage}
        />
    )
}