import React, { useCallback, useState } from "react";
import './styles.css';
import { ISelectCountry, countryType } from "./interfaces";
import { DesktopSection, ScreenWrapper, SelectCountry } from "../../components";
import { useTranslation } from "../../utils";

export const SelectCountryComponent:React.FC<ISelectCountry> = ({
    onNextPage,
    onPrevPage,
    selectedCountryDefault,
})=> {

    const translate = useTranslation();
    const [selectedCountry, setSelectedCountry] = useState<countryType | null>(selectedCountryDefault);

    const handleSelectedCountry = useCallback(()=> {
        if(!selectedCountry) return;

        onNextPage(selectedCountry);
    }, [onNextPage, selectedCountry]);

    const renderContent = useCallback(()=> (
        <div className="pc_selectCountryContainer">
            <SelectCountry 
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
            />
        </div>
    ), [selectedCountry]);

    return (
        <ScreenWrapper
            isBlur
            headTitle="1 — 5"
            headSubTitle={translate('what_country_are_you_in')}
            bottomButtonLabel={translate('next')}
            bottomButtonPress={handleSelectedCountry}
            onGoBackPress={onPrevPage}
        >
            <DesktopSection>
                {renderContent()}
            </DesktopSection>
        </ScreenWrapper>
    )
}