import React, { useCallback, useMemo, useState } from "react";
import './styles.css';
import { IYourMood, moodType } from "./interfaces";
import { Button, DesktopSection, ScreenWrapper } from "../../components";
import { useTranslation } from "../../utils";

export const YourMoodComponent:React.FC<IYourMood> = ({
    selectedMoodsDefault,
    onNextPage,
    onPrevPage,
})=> {

    const translate = useTranslation();
    const [selectedMoods, setSelectedMoods] = useState<moodType>(selectedMoodsDefault[0]);

    const moodsList = useMemo(()=> (
        [
            {name: translate('happy'), color: '#fff', backgroundColor: '#000'},
            {name: translate('romantic'), color: '#fff', backgroundColor: '#000'},
            {name: translate('inspired'), color: '#fff', backgroundColor: '#000'},
            {name: translate('scared'), color: '#fff', backgroundColor: '#000'},
            {name: translate('adventurous'), color: '#fff', backgroundColor: '#000'},
            {name: translate('nostalgic'), color: '#fff', backgroundColor: '#000'},
            {name: translate('curious'), color: '#fff', backgroundColor: '#000'},
            {name: translate('calm'), color: '#fff', backgroundColor: '#000'},
            {name: translate('energetic'), color: '#fff', backgroundColor: '#000'},
            {name: translate('thoughtful'), color: '#fff', backgroundColor: '#000'},
            {name: translate('melancholic'), color: '#fff', backgroundColor: '#000'},
            {name: translate('mysterious'), color: '#fff', backgroundColor: '#000'},
            {name: translate('whimsical'), color: '#fff', backgroundColor: '#000'},
            {name: translate('amused'), color: '#fff', backgroundColor: '#000'},
            {name: translate('family'), color: '#fff', backgroundColor: '#000'},
            {name: translate('no_reference_mood'), color: '#fff', backgroundColor: '#000'},
        ]
    ), [translate]);

    const handleSelectedKind = useCallback((el: moodType)=> {
        setSelectedMoods(el);
    }, []);

    const handleNextPage = useCallback(()=> {
        if(!selectedMoods) return;

        onNextPage([selectedMoods!]);
    }, [onNextPage, selectedMoods]);

    const renderContent = useCallback(()=> (
        <div className="pc_yourMood_container">
            <div className="pc_yourMood_listItems">
                {
                    moodsList.map((el, i)=> {
                        const exist = el.name === selectedMoods?.name;
                        return (
                            <Button
                                key={i}
                                label={el.name}
                                onPress={()=> handleSelectedKind(el)}
                                color={exist ? el.color : ''}
                                backgroundColor={exist ? el.backgroundColor : ''}
                                noBorder={exist}
                            />
                        )
                    })
                }
            </div>
        </div>
    ), [handleSelectedKind, moodsList, selectedMoods]);

    return (
        <ScreenWrapper
            isBlur
            headTitle="4 — 5"
            headSubTitle={translate('what_mood_do_you_want_to_be_in_right_now')}
            bottomButtonLabel={translate('next')}
            onGoBackPress={onPrevPage}
            bottomButtonPress={handleNextPage}
        >
            <DesktopSection>
                {renderContent()}
            </DesktopSection>
        </ScreenWrapper>
    )
}