import { useState, useEffect } from 'react';

interface Translation {
    [key: string]: string;
}

export const useTranslation = () => {

    const userLanguage = navigator.language;
    const isFR = userLanguage.toLocaleLowerCase().includes('fr');

    const locale = isFR ? 'fr' : 'en';

    const [translations, setTranslations] = useState<Translation>({});

    useEffect(() => {
        async function loadTranslations() {
            try {
                const response = await import(`../locales/${locale}.json`);
                setTranslations(response.default);
            } catch (error) {
                console.error(`Could not load translations for locale: ${locale}`, error);
            }
        }

        loadTranslations();
    }, [locale]);

    return (key: string) => translations[key] || key;
};
