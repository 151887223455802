import React from "react";
import './styles.css';
import { IMovieDetails } from "./interfaces";
import { ScreenWrapper } from "../../components";

export const MovieDetailsComponent:React.FC<IMovieDetails> = ({
    
})=> {
    return (
        <ScreenWrapper isBlur>
            <div className="">
                
            </div>
        </ScreenWrapper>
    )
}