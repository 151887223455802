import React, { useCallback, useEffect, useState } from "react";
import './styles.css';
import { IMoviesResults } from "./interfaces";
import { Button, GradianButton, ScreenWrapper } from "../../components";

import { ProgressSpinner } from 'primereact/progressspinner';

import IMDB_Logo from '../../assets/IMDB_Logo.svg';
import InfoIcon from '../../assets/icons/infos.svg';
import { MediaEntry, useTranslation } from "../../utils";
import { useController } from "./useController";

export const MoviesResultsComponent:React.FC<IMoviesResults> = ({
    isLoading,
    mediaEntries,
    // onNextPage,
    // onPrevPage,
    onGetMoreSuggestion,
    onGetNewSearch,
})=> {

    const {
        getYear,
        formatTextMaxChars,
        getGenres,
    } = useController();
    const translate = useTranslation();

    const [movieIndex, setMovieIndex] = useState<number | null>(null);

    // const handlePrevPage = useCallback(()=> {
    //     if(!isLoading){
    //         onPrevPage();
    //     }
    // }, [isLoading, onPrevPage]);
    
    const handleNewSearch = useCallback(()=> {
        if(!isLoading){
            onGetNewSearch();
        }
    }, [isLoading, onGetNewSearch]);

    const handleGetMoreSuggestion = useCallback(()=> {
        if(!isLoading){
            onGetMoreSuggestion();
        }
    }, [isLoading, onGetMoreSuggestion]);

    const onToggleShowDescription = useCallback((index: number)=> {
        setMovieIndex(movieIndex === index ? null : index);
    }, [movieIndex]);

    const renderMovieStreamingServices = useCallback((movie: MediaEntry)=> {

        const streamON = movie.offers.filter(el=> el.monetizationType === 'FLATRATE');
        const rentON = movie.offers.filter(el=> el.monetizationType === 'RENT');

        return (
            <div className="pc_pc_movie_item_content_sectionContainer pc_space_arround">
                <div className="pc_pc_movie_item_content_sectionContainer">
                    <div className="pc_streamOrRent_container">
                        <p className="pc_streamOrRent_txt">
                            {translate('stream')}
                        </p>
                        <p className="pc_streamOrRent_txt">
                            {translate('on')}
                        </p>
                    </div>
                    {
                        streamON.map((offer, j)=> {
                            if(j < 2){
                                return (
                                    <img 
                                        key={j}
                                        alt={offer.platform}
                                        src={offer.icon}
                                        className="pc_movie_item_offer_icon"
                                    />
                                )
                            }
                        })
                    }
                </div>
                {
                    rentON.length > 0 && (
                        <div className="pc_pc_movie_item_content_sectionContainer">
                            <div className="pc_streamOrRent_container">
                                <p className="pc_streamOrRent_txt">
                                    {translate('rent')}
                                </p>
                                <p className="pc_streamOrRent_txt">
                                    {translate('on')}
                                </p>
                            </div>
                            {
                                rentON.map((offer, j)=> {
                                    if(j < 2){
                                        return (
                                            <img 
                                                key={j}
                                                alt={offer.platform}
                                                src={offer.icon}
                                                className="pc_movie_item_offer_icon"
                                            />
                                        )
                                    }
                                })
                            }
                        </div>
                    )
                }
            </div>
        )
    }, [translate]);

    const renderMovieRateAndDescription = useCallback((
        movie: MediaEntry,
        showDescription: boolean, 
        index: number
    )=> {
        return (
            <div className="pc_pc_movie_item_content_footer_sectionContainer">
                <div className="pc_pc_movie_item_content_footerHeader">
                    <div className="pc_imdb_rate_container">
                        <img src={IMDB_Logo} alt="imdb logo" width={40}/>

                        <p className="pc_imdb_rate">
                            {movie.imdb_rate?.rate || 'N/A'}
                            {/* <span> / 10</span> */}
                        </p>
                    </div>

                    <div className="pc_genres_container">
                        {
                            getGenres(movie.genres).map((genre, g)=> {
                                if(g > 1) return;

                                const showComas = g === 0 && getGenres(movie.genres).length > 1;

                                return (
                                    <p
                                        key={g}
                                        className="pc_genre_txt"
                                    >
                                        {genre}{showComas && ','} 
                                    </p>
                                )
                            })
                        }
                    </div>

                    <p className="pc_genre_txt">
                        {movie.runtime_minutes}min
                    </p>
                    
                    <img 
                        className="info_icon"
                        src={InfoIcon} 
                        alt="icon infos" 
                        onClick={()=> onToggleShowDescription(index)}
                    />
                </div>

                {
                    showDescription && (
                        <p className="pc_imdb_description">
                            {movie.short_description}
                        </p>
                    )
                }
            </div>
        )
    }, [getGenres, onToggleShowDescription]);

    const renderLoadingButtonContent = useCallback(()=> {
        return (
            <ProgressSpinner 
                style={{width: '30px', height: '30px'}} 
                strokeWidth="8"
                animationDuration="1.5s" 
            />
        )
    }, []);

    if(isLoading && mediaEntries.length === 0){
        return (
            <ScreenWrapper
                isBlur
            >
                <div className="pc_moviesResultContainer_loadingContainer">
                    <p className="pc_moviesResultContainer_loadingContainer_text">
                        {translate('hang_tight')}
                    </p>

                    <ProgressSpinner 
                        style={{width: '70px', height: '70px'}} 
                        strokeWidth="8"
                        animationDuration="1.5s" 
                    />
                </div>
            </ScreenWrapper>
        )
    }

    return (
        <ScreenWrapper
            isBlur
            headSubTitle={translate('your_list')}
            // onGoBackPress={handlePrevPage}
        >
            <div className="pc_moviesResultContainer">
                
                <div className="pc_movie_list_container">
                    <div className="pc_movie_list_containerSection">
                    {
                        mediaEntries.map((el, i)=> {
                            const showDescription = i === movieIndex

                            return (
                                <div
                                    key={i}
                                    className="pc_movie_item_container"
                                    style={{
                                        backgroundImage: `url(${el.poster})`
                                    }}
                                    onClick={()=> onToggleShowDescription(i)}
                                >
                                    <div className="pc_movie_item_subcontainer">
                                        <div className={`pc_movie_item_bottomShadow ${showDescription && 'pc_movie_item_bottomShadowActive'}`}>
                                            <div className="pc_pc_movie_item_content_Container">
                                                <div className="pc_pc_movie_item_content_title_section">
                                                    <p className="pc_movie_item_content_title">
                                                        {formatTextMaxChars(el.originalTitle, 25)}
                                                    </p>
                                                    <p className="pc_movie_item_content_date">
                                                        {`(${getYear(new Date(el.release_date))})`}
                                                    </p>
                                                </div>

                                                {renderMovieStreamingServices(el)}

                                                {renderMovieRateAndDescription(el, showDescription, i)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )   
                        })
                    }
                    </div>

                    <div className="pc_movieResult_actionsButtons_container">
                        {
                            mediaEntries.length > 0 && (
                                <GradianButton 
                                    label={isLoading ? renderLoadingButtonContent() : translate('more_suggestions')}
                                    onPress={handleGetMoreSuggestion}
                                    classname="pc_movieResult_moreButton_container"
                                    classnameButton="pc_movieResult_moreButton"
                                />
                            )
                        }
                        
                        {
                            !isLoading && (
                                <Button 
                                    label={translate('new_search')}
                                    onPress={handleNewSearch}
                                    classname="pc_movieResult_newSearchButton"
                                />
                            )
                        }
                        
                    </div>
                </div>
            </div>
        </ScreenWrapper>
    )
}