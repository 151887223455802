import React from "react";
import { MoviesResultsComponent } from "./MoviesResults.component";
import { IMoviesResults } from "./interfaces";

export const MoviesResults:React.FC<IMoviesResults> = ({
    isLoading,
    mediaEntries,
    // onNextPage,
    onPrevPage,
    onGetMoreSuggestion,
    onGetNewSearch,
})=> {
    return (
        <MoviesResultsComponent 
            mediaEntries={mediaEntries}
            isLoading={isLoading}
            // onNextPage={onNextPage}
            onPrevPage={onPrevPage}
            onGetMoreSuggestion={onGetMoreSuggestion}
            onGetNewSearch={onGetNewSearch}
        />
    )
}