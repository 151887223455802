import React, { useCallback, useMemo, useState } from "react";
import './styles.css';
import { ISelectStreamingServices, serviceStreamintType } from "./interfaces";
import { Button, DesktopSection, ScreenWrapper } from "../../components";
import { useTranslation } from "../../utils";

export const SelectStreamingServicesComponent:React.FC<ISelectStreamingServices> = ({
    onNextPage,
    onPrevPage,
    selectedServicesDefault,
})=> {

    const translate = useTranslation();
    const [selecteServices, setSelectedService] = useState<serviceStreamintType[]>(selectedServicesDefault);

    const streamingServicesList = useMemo(()=> (
        [
            {name: 'Disney+', color: '#fff', backgroundColor: '#000C7C'},
            {name: 'Prime Video', color: '#fff', backgroundColor: '#1399FF'},
            {name: 'Netflix', color: '#fff', backgroundColor: '#D93221'},
            {name: 'Pass Warner', color: '#fff', backgroundColor: '#FCC00F'},
            {name: 'Hulu', color: '#fff', backgroundColor: '#1DE783'},
            {name: 'HBO Max', color: '#fff', backgroundColor: '#5822B4'},
            {name: 'OCS Go', color: '#fff', backgroundColor: '#FF6600'},
            {name: 'Canal+', color: '#fff', backgroundColor: '#626161'},
            {name: 'Apple TV+', color: '#000', backgroundColor: '#fff'},
            {name: 'Paramount+', color: '#fff', backgroundColor: '#0067FF'},
            {name: 'Arte', color: '#fff', backgroundColor: '#FD4700'},
            {name: 'Canal VOD', color: '#fff', backgroundColor: '#91278F'},
            {name: 'Crunchyroll', color: '#fff', backgroundColor: '#EB711E'},
            {name: 'ADN', color: '#fff', backgroundColor: '#21F2F7'},
        ]
    ), []);

    const handleSelectService = useCallback((el: serviceStreamintType)=> {
        const exist = !!selecteServices.find(element=> element.name === el.name);

        if(exist){
            setSelectedService(tmp=> (
                tmp.filter(service=> service.name !== el.name)
            ));
        } else {
            setSelectedService(tmp=> [...tmp, el]);
        }
    }, [selecteServices]);

    const handleNextPage = useCallback(()=> {
        if(selecteServices.length === 0) return;

        onNextPage(selecteServices);
    }, [onNextPage, selecteServices]);

    const renderContent = useCallback(()=> (
        <div className="pc_selectStreamingService_container">
            <div className="pc_selectStreamingService_listItems">
                {
                    streamingServicesList.map((el, i)=> {
                        const exist = !!selecteServices.find(element=> element.name === el.name);
                        return (
                            <Button 
                                key={i}
                                label={el.name}
                                onPress={()=> handleSelectService(el)}
                                color={exist ? el.color : ''}
                                backgroundColor={exist ? el.backgroundColor : ''}
                                noBorder={exist}
                            />
                        )
                    })
                }
            </div>
        </div>
    ), [handleSelectService, selecteServices, streamingServicesList]);

    return (
        <ScreenWrapper
            isBlur
            headTitle="2 — 5"
            headSubTitle={translate('what_streaming_services_do_you_use')}
            bottomButtonLabel={translate('next')}
            onGoBackPress={onPrevPage}
            bottomButtonPress={handleNextPage}
        >
            <DesktopSection>
                {renderContent()}
            </DesktopSection>
        </ScreenWrapper>
    )
}