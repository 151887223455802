import React from "react";
import './styles.css';
import { IButton } from "./interfaces";

import { Button as ButtonComponent } from 'primereact/button';

export const Button:React.FC<IButton> = ({
    label,
    onPress,
    color,
    backgroundColor,
    noBorder = false,
    classname,
})=> {
    return (
        <ButtonComponent 
            rounded
            label={label}
            onClick={onPress}
            className={`pc_button_container ${noBorder && 'pc_noBorder'} ${classname}`}
            style={color ? {
                color: color, 
                backgroundColor: backgroundColor, 
                borderColor: backgroundColor,
            } : {}}
        />
    )
}