import React from "react";
import './styles.css';
import { IDesktopSection } from "./interfaces";

export const DesktopSection:React.FC<IDesktopSection> = ({
    children
})=> {
    return (
        <div className="pc_desktosectionContainer">
            <div className="pc_desktosectionSubContainer">
                {children}
            </div>
        </div>
    )
}