import React from "react";
import { MovieDetailsComponent } from "./MovieDetails.component";

interface Props {
    
}

export const MovieDetails:React.FC<Props> = ({
    
})=> {
    return (
        <MovieDetailsComponent 
            
        />
    )
}