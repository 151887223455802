import React from "react";
import './styles.css';
import { IGradianButton } from "./interfaces";

export const GradianButton:React.FC<IGradianButton> = ({
    label,
    onPress,
    classname,
    classnameButton,
})=> {
    return (
        <div className={`pc_gradianButton_container ${classname}`}>
            <div 
                className={`pc_gradianButton_button ${classnameButton}`}
                onClick={()=> {
                    // navigator.vibrate(200);
                    onPress();
                }}
            >
                {label}
            </div>
        </div>
    )
}