import React from "react";
import { MySpecificationComponent } from "./MySpecification.component";
import { IMySpecification } from "./interfaces";

export const MySpecification:React.FC<IMySpecification> = ({
    specificationsDefault,
    onNextPage,
    onPrevPage
})=> {
    return (
        <MySpecificationComponent 
            specificationsDefault={specificationsDefault}
            onNextPage={onNextPage}
            onPrevPage={onPrevPage}
        />
    )
}